import { useRef, useEffect, useCallback } from 'react';
import { isBrowser } from '../../core/commons/utils';

export type Message =
  | {
      type: string;
      data?: any;
    }
  | string;

export type IFrameEvent = {
  type: 'load' | 'unload' | 'message';
  payload?: Message;
};

type ISendMessageArg =
  | string
  | number
  | boolean
  | Record<string, any>
  | Array<any>;

export type IFrameEventType = IFrameEvent['type'];
export type ISendMessage = (message: ISendMessageArg) => void;
export type IIFrameReducer = (
  event: IFrameEvent,
  sendMessage: ISendMessage,
) => void;

/**
 * Hook to interact with iframe - subscribe to events/postMessages and send postMessages
 * @param {IIFrameReducer} [reducer] - callback which will be called on load/unload/message events
 * @returns {(node: HTMLIFrameElement) => void, ISendMessage]} - tuple containing ref and function to send messages to iframe
 */
export function useIFrame(
  reducer: IIFrameReducer = () => ({}),
): [(node: HTMLIFrameElement) => void, ISendMessage] {
  const iframe = useRef<HTMLIFrameElement | undefined>(undefined);
  const iframeCleanupFunction = useRef<(() => void) | undefined>(undefined);

  const sendMessage: ISendMessage = useCallback(
    (
      message: ISendMessageArg,
      targetOrigin = '*',
      transfer?: Array<Transferable>,
    ) => {
      if (!iframe.current) {
        return;
      }

      const iFrameWindow = iframe.current.contentWindow;
      iFrameWindow?.postMessage(message, targetOrigin, transfer);
    },
    [],
  );

  const ref = useCallback(
    (node: HTMLIFrameElement) => {
      if (iframeCleanupFunction.current) {
        iframeCleanupFunction.current();
        iframeCleanupFunction.current = undefined;
      }

      if (!node) {
        return;
      }

      const loadListener = () => reducer({ type: 'load' }, sendMessage);
      node.addEventListener('load', loadListener);

      iframe.current = node;

      iframeCleanupFunction.current = () => {
        node.removeEventListener('load', loadListener);
      };
    },
    [reducer, sendMessage],
  );

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    const messageListener = (message: MessageEvent) => {
      // !message.source if for ie11 fallback
      if (!message.source || message.source === iframe.current?.contentWindow) {
        reducer({ type: 'message', payload: message.data }, sendMessage);
      }
    };

    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [reducer, sendMessage]);

  return [ref, sendMessage];
}
